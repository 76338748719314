<template>
  <div class="container">
    <strong>เพิ่มผู้ช่วย</strong>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <b-form-group
              label="ชื่อผู้ใช้ *"
              :description="`ความยาว 1-10 ตัวอักษร ภาษาอังกฤษ a-Z หรือตัวเลข 0-9 เท่านั้น`"
              :state="invalidKeys.username.status"
              :invalid-feedback="invalidKeys.username.text"
            >
              <b-input-group
                :append="userAppend"
              >
                <b-form-input
                  v-model="input.username"
                  type="text"
                  placeholder="ชื่อผู้ใช้"
                  required
                  :state="invalidKeys.username.status"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              size="sm"
              label="รหัสผ่าน *"
              :state="invalidKeys.password.status && validPassword"
            >
              <b-form-input
                v-model="input.password"
                type="password"
                placeholder="รหัสผ่าน"
                minLength="6"
                required
                :state="invalidKeys.password.status && validPassword"
              ></b-form-input>
              <small class="form-text text-muted">
                <ul class="pl-3">
                  <li>ความยาวอย่างน้อย 8 ตัวอักษร <i class="fas fa-check text-success" v-if="pwLength"></i></li>
                  <li>อักษรภาษาอังกฤษพิมพ์ใหญ่ อย่างน้อย 1 ตัว <i class="fas fa-check text-success" v-if="pwUppercase"></i></li>
                  <li>อักษรภาษาอังกฤษพิมพ์เล็ก อย่างน้อย 1 ตัว <i class="fas fa-check text-success" v-if="pwLowercase"></i></li>
                  <li>ตัวเลข อย่างน้อย 1 ตัว <i class="fas fa-check text-success" v-if="pwNumber"></i></li>
                  <li>อักษรพิเศษ อย่างน้อย 1 ตัว <i class="fas fa-check text-success" v-if="pwSymbol"></i></li>
                </ul>
              </small>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              label="โทรศัพท์"
            >
              <b-form-input
                v-model="input.tel"
                type="text"
                placeholder="โทรศัพท์"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <b-form-group
              label="ชื่อ *"
              :state="invalidKeys.firstName.status"
              :invalid-feedback="invalidKeys.firstName.text"
            >
              <b-form-input
                v-model="input.firstName"
                type="text"
                placeholder="ชื่อ"
                required
                :state="invalidKeys.firstName.status"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              label="นามสกุล"
            >
              <b-form-input
                v-model="input.lastName"
                type="text"
                placeholder="นามสกุล"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              label="Line ID"
            >
              <b-form-input
                v-model="input.lineId"
                type="text"
                placeholder="Line ID"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <Permission class="mt-3" :input="input" />
      </div>
      <div class="card-footer text-right">
        <button class="btn btn-primary" :disabled="isProcess" @click="createAssistant">{{isProcess ? 'กำลังบันทึก...' : 'เพิ่มผู้ช่วย'}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import { passwordStrength } from 'check-password-strength'
import Permission from './components/Permission'

import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'
import AssistantService from '@/services/AssistantService'

const invalidKeys = {
  username: {
    status: null,
    text: null
  },
  password: {
    status: null,
    text: null
  },
  firstName: {
    status: null,
    text: null
  }
}

export default {
  name: 'CreateAssistant',
  components: {
    Permission
  },
  data() {
    return {
      isProcess: false,
      input: {
        append: '',
        username: '',
        password: '',
        firstName: '',
        lastName: '',
        tel: '',
        lineId: '',
        permission: {
          BetReport: 'r',
          WaitReport: 'r',
          OverallReport: 'r',
          WinLose: 'r',
          WinLose2: 'r',
          MemberReport: 'r',
          TicketReport: 'w',
          LimitNumbers: 'r',

          WinLoseReport: 'r',
          MarketWinLose: 'r',
          MemberTypeWinLose: 'r',
          WonTickets: 'r',
          Results: 'r',

          RoundSetting: 'r',
          Payrate: 'w',
          RoundReceive: 'w',
          MarketReceive: 'w',
          SettingHistory: 'r',

          Finance: 'r',
          FinanceReport: 'r',
          FinanceMember: 'w',
          AccountLog: 'r',

          ManageAccount: 'r',
          CreateMember: 'w',
          CreateAgent: 'w',
          MemberSetting: 'w',
          BlockMember: 'w',
          NewMemberSetting: 'w',

          AccountSetting: 'r',
          MemberMarket: 'w',
          MemberSharing: 'w',
          MemberPayout: 'w',
          MemberBetLimit: 'w',
          MemberLottoSet: 'w'
        }
      },
      invalidKeys: JSON.parse(JSON.stringify(invalidKeys))
    }
  },
  computed: {
    agentUsername() {
      return this.$store.state?.userProfile?.username || ''
    },
    userAppend() {
      return `@${this.agentUsername}`
    },
    pwStrength() {
      return passwordStrength(this.input.password)
    },
    pwLength() {
      return this.pwStrength.length >= 8
    },
    pwLowercase() {
      return this.pwStrength.contains.includes('lowercase')
    },
    pwUppercase() {
      return this.pwStrength.contains.includes('uppercase')
    },
    pwNumber() {
      return this.pwStrength.contains.includes('number')
    },
    pwSymbol() {
      return this.pwStrength.contains.includes('symbol')
    },
    validPassword() {
      if(!this.input.password)
        return null

      return this.pwLength && this.pwLowercase && this.pwUppercase && this.pwNumber && this.pwSymbol
    }
  },
  methods: {
    createAssistant() {

      let invalidCount = 0
      this.invalidKeys = JSON.parse(JSON.stringify(invalidKeys))

      /**
       * ตรวจสอบ username
       */
      if(!this.input.username) {
        this.invalidKeys = {
          ...this.invalidKeys,
          username: {
            status: false,
            text: 'กรูณาระบุชื่อผู้ใช้'
          }
        }
        invalidCount++
      }else
      if(!/^([a-zA-Z0-9]{1,20})$/.test(this.input.username)) {
        this.invalidKeys = {
          ...this.invalidKeys,
          username: {
            status: false,
            text: 'ชื่อผู้ใช้ไม่ถูกต้อง'
          }
        }
        invalidCount++
      }

      /**
       * ตรวจสอบรหัสผ่าน
       */
      if(!this.input.password) {
        this.invalidKeys = {
          ...this.invalidKeys,
          password: {
            status: false,
            text: 'กรุณาระบุรหัสผ่าน'
          }
        }
        invalidCount++
      }else
      if(!this.validPassword) {
        this.invalidKeys = {
          ...this.invalidKeys,
          password: {
            status: false,
            text: 'รูปแบบรหัสผ่านไม่ถูกต้อง'
          }
        }
        invalidCount++
      }

      /**
       * ตรวจสอบชื่อ
       */
      if(this.input.firstName.length < 1) {
        this.invalidKeys = {
          ...this.invalidKeys,
          firstName: {
            status: false,
            text: 'กรุณากรอกชื่อ'
          }
        }
        invalidCount++
      }

      if(invalidCount > 0) {
        Swal.fire({
          text: 'กรุณาตรวจสอบข้อมูล',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return
      }

      this.isProcess = true
      AssistantService.createAssistant({...this.input, append: this.userAppend})
      .then((response)=>{
        console.log('response', response)
        if(response.success) {
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'เพิ่มผู้ช่วยเสร็จเรียบร้อย',
          })
          this.$router.push({name: 'Assistants'})
        }else{
          throw response
        }
      })
      .catch((e)=>{
        const errors = []
        if(e.success === false) {
          if(e?.errors) {
            e.errors.forEach((error)=>{
              this.findError(error)
              errors.push(error?.detail)
            })
          }
        }

        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: 'สร้างผู้ช่วยไม่สำเร็จ กรุณาตรวจสอบข้อมูล',
          message: errors?.[0],
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    findError(error) {
      if(error?.source?.children) {
        error.source?.children.forEach((error)=>{
          this.findError(error)
        })
      }else
      if(error?.parameter){
        this.invalidKeys[error.parameter] = {
          status: false,
          text: error?.detail
        }
      }else
      if(error?.source){
        this.invalidKeys[error.source.parameter] = {
          status: false,
          text: error?.detail
        }
      }
    }
  }
}
</script>
